import React, { useState, useEffect } from 'react';
import { getSessionToken, removeSessionToken, getUserType } from '../utils/Auth';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/AdminCulture.module.css';

const ItemType = {
    TRAIT: 'trait',
};

const TraitItem = ({ trait, index, moveTrait, userType }) => {
    const [isDropped, setIsDropped] = useState(false);

    const [{ isDragging }, ref] = useDrag({
        type: ItemType.TRAIT,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: userType === 'admin',
    });

    const [, drop] = useDrop({
        accept: ItemType.TRAIT,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveTrait(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
        drop: () => {
            setIsDropped(true);
            setTimeout(() => setIsDropped(false), 1000);
        },
        canDrop: () => userType === 'admin',
    });

    return (
        <div
            ref={(node) => drop(ref(node))}
            className={`${styles["trait-item"]} ${isDragging || isDropped ? styles["highlight"] : ""}`}
        >
            <FontAwesomeIcon icon={faGripVertical} className={styles["grip-icon"]} />
            <span className={styles["trait-value"]}>{index + 1}</span>
            <span className={styles["trait-name"]}>
                {trait.trait_name.charAt(0).toUpperCase() + trait.trait_name.slice(1)}
            </span>
        </div>
    );
};

const AdminCulture = ({ company_id }) => {
    const [traits, setTraits] = useState([]);
    const [userType, setUserType] = useState(null);

    const navigate = useNavigate();

    const token = getSessionToken();
    if (!token) {
        navigate('/login');
    } else {
        getUserType().then(type => setUserType(type));
    }

    useEffect(() => {
        if (token && traits.length === 0) {
            fetch('https://api.wanteed-job.com/company/get-culture?company_id=' + company_id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            removeSessionToken();
                            navigate('/login');
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    setTraits(data);
                })
                .catch((error) => console.error('Error fetching culture traits:', error));
        }
    }, [token, traits, company_id, navigate]);

    const moveTrait = (fromIndex, toIndex) => {
        const updatedTraits = [...traits];
        const [movedTrait] = updatedTraits.splice(fromIndex, 1);
        updatedTraits.splice(toIndex, 0, movedTrait);
        setTraits(updatedTraits);
    };

    const handleSubmit = async () => {
        const cultureBody = traits.map((trait, index) => ({
            trait_id: String(trait.trait_id),
            value: Math.max(1, 10 - index),
        }));

        try {
            const response = await fetch('https://api.wanteed-job.com/company/answer-culture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token,
                },
                body: JSON.stringify({ company_id: company_id, cultures: cultureBody }),
            });
            if (!response.ok) throw new Error('Network response was not ok');
        } catch (error) {
            console.error('Failed to submit culture traits:', error);
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={styles["culture-fit-page"]}>
                <h1 className="h1">Votre culture d'entreprise</h1>
                <p className="p">
                    Classez les critères suivants selon l'importance que vous leur accordez dans votre environnement de travail.
                </p>
                {userType !== 'admin' && (
                    <p className="p">
                        Vous n'avez pas les permissions nécessaires pour modifier les critères de culture d'entreprise.
                    </p>
                    )}
                <div className={styles["traits-grid"]}>
                    {traits.map((trait, index) => (
                        <TraitItem
                            key={trait.trait_id}
                            trait={trait}
                            index={index}
                            moveTrait={moveTrait}
                            length={traits.length}
                            userType={userType}
                        />
                    ))}
                </div>
                {userType === 'admin' && (
                    <button className={styles["validate-button"]} onClick={handleSubmit}>
                        Valider
                    </button>
                )}
            </div>
        </DndProvider>
    );
};

export default AdminCulture;
