import React, { useState, useEffect, useCallback } from 'react';
import { getSessionToken, setSessionToken } from '../utils/Auth';
import TestSwitcher from '../components/TestSwitcher';
import styles from '../styles/SoftSkillTest.module.css';

const SoftSkillTest = () => {
    const [question, setQuestion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [allQAnswer, setAllQAnswer] = useState(false);
    const [dummyRegister, setDummyRegister] = useState(false);

    const fetchDummyRegister = async () => {
        try {
            const response = await fetch('https://api.wanteed-job.com/credential/get-dummy-register', {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setToken(data);
            setSessionToken(data);
            localStorage.setItem('dummyRegister', 'true');
            setDummyRegister(true);
        } catch (error) {
            console.error('Failed to get session key. Please try again.');
        }
    };

    const fetchQuestion = useCallback(async () => {
        try {
            const response = await fetch('https://api.wanteed-job.com/questions/get-softskill?tunnel=' + dummyRegister, {
                method: 'GET',
                headers: {
                    'session-key': `${token}`
                }
            });
            if (response.status === 404) {
                setAllQAnswer(true);
                return;
            }
            const data = await response.json();
            setQuestion(data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch question');
        }
    }, [token, dummyRegister]);

    const fetchCount = useCallback(async () => {
        try {
            const response = await fetch('https://api.wanteed-job.com/questions/count-softskill?tunnel=' + dummyRegister, {
                method: 'GET',
                headers: {
                    'session-key': `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            if (response.status === 401) {
                setAllQAnswer(true);
                return;
            }
            const data = await response.json();
            setCurrentQuestion(data.answered);
            setTotalQuestions(data.total);
        } catch (error) {
            console.error('Failed to fetch questions count');
        }
    }, [token, dummyRegister]);

    const handleAnswer = async (value) => {
        try {
            fetch('https://api.wanteed-job.com/questions/answer-softskill', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token,
                },
                body: JSON.stringify({
                    question_id: question.question_id,
                    value: value,
                    time: 0,
                }),
            });

            fetchQuestion();
            fetchCount();
        } catch (error) {
            console.error('Failed to answer question:', error);
        }
    };

    
    useEffect(() => {
        const token = getSessionToken();
        setDummyRegister(localStorage.getItem('dummyRegister') === 'true');

        if (!token) {
            fetchDummyRegister().then(() => {
                fetchCount();
                fetchQuestion();
            });
        } else {
            setToken(token);
            fetchCount();
            fetchQuestion();
        }
    }, [fetchCount, fetchQuestion]);

    return (
        <div className={styles["softskill-test"]}>
            <TestSwitcher activeTest="softskills" />
            <h1 className={styles["test-title"]}>Vos qualités</h1>
            <p className={styles["test-description"]}>Cliquez sur une bulle pour indiquer quelle affirmation vous correspond le mieux.<br />La bulle au centre indique que vous êtes neutre.</p>
            
            {!dummyRegister ? (
                <p className={styles["test-description"]}>Vous avez répondu à {currentQuestion} questions sur {totalQuestions}.</p>
            ) : (
                <div className={styles["progress-bar"]}>
                    <div className={styles["progress"]} style={{width: `${(currentQuestion / totalQuestions) * 100}%`}}></div>
                </div>
            )}
            
            {loading ? (
                <p className={styles["loading"]}>Loading...</p>
            ) :  (
                question && (
                    <>
                    { allQAnswer ? (
                    <div className={styles["question-container"]}>
                        <p className={styles["question-end"]}>Il n'y a plus de questions à répondre</p>
                    <div>
                        <button onClick={() => window.location.href = '/hardskills'}>Passer à la page suivante</button>
                    </div>
                </div>
                ) : (
                    <div className={styles["question-container"]}>
                        <div className={styles["question-options"]}>
                            <p className={styles["question"]}>{question.question_a}</p>
                            <p className={styles["question"]}>{question.question_b}</p>
                        </div>
                        <div className={styles["scale"]}>
                            <span onClick={() => handleAnswer(-3)} className={styles["left3"]}></span>
                            <span onClick={() => handleAnswer(-2)} className={styles["left2"]}></span>
                            <span onClick={() => handleAnswer(-1)} className={styles["left1"]}></span>
                            <span onClick={() => handleAnswer(0)} className={styles["neutral"]}></span>
                            <span onClick={() => handleAnswer(1)} className={styles["right1"]}></span>
                            <span onClick={() => handleAnswer(2)} className={styles["right2"]}></span>
                            <span onClick={() => handleAnswer(3)} className={styles["right3"]}></span>
                        </div>
                    </div>
                )}
                    </>
                )
            )}
        </div>
    );
};

export default SoftSkillTest;
