import React, { useState, useEffect } from 'react';
import { getSessionToken, getUserType } from '../../utils/Auth';
import { useNavigate } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from './ProcessCandidate.module.css';
import Modal from '../Modal';

const ProcessCandidate = ({ jobData }) => {
    const [candidates, setCandidates] = useState({});
    const [columns, setColumns] = useState([]);
    const [newColumnName, setNewColumnName] = useState('');
    const [newColumnIndex, setNewColumnIndex] = useState('');
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [userType, setUserType] = useState(null);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = getSessionToken();
        if (!token) {
            navigate('/login');
        } else {
            getUserType().then(type => setUserType(type));
        }
        setToken(token);
    }, [navigate]);


    const job_id = jobData.job_id;
    const company_id = jobData.company_id;

    const sendupdate = async (candidate_list, column_list) => {

        setCandidates(candidate_list);
        setColumns(column_list);

        const candidates_update = [];
        Object.keys(candidate_list).forEach(column => {
            candidate_list[column].forEach(candidate => {
                candidates_update.push({
                    user_id: candidate.id,
                    column_index: column_list.indexOf(column),
                });
            });
        });

        const data = {
            job_id: job_id,
            company_id: company_id,
            columns: column_list,
            candidates: candidates_update
        };

        const response = await fetch('https://api.wanteed-job.com/candidate/update-process', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-key': token,
            },
            body: JSON.stringify(data),
        });

        if (response.status !== 200) {
            console.error("Error updating candidates");
            return;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://api.wanteed-job.com/candidate/get-process?job_id=' + jobData.job_id + '&company_id=' + jobData.company_id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token,
                },
            });

            if (response.status !== 200) {
                console.error("Error fetching candidates");
                return;
            }

            const data = await response.json();

            const candidates = data.candidate;
            setColumns(data.columns);

            const initialCandidates = data.columns.reduce((acc, column) => {
                acc[column] = [];
                return acc;
            }, {});

            candidates.forEach(candidate => {
                const columnName = data.columns[candidate.column_index];
                if (columnName) {
                    initialCandidates[columnName].push({
                        id: candidate.user_id,
                        name: `${candidate.first_name} ${candidate.last_name}`,
                    });
                }
            });

            Object.keys(initialCandidates).forEach(column => {
                initialCandidates[column].sort((a, b) => a.name.localeCompare(b.name));
            });

            setCandidates(initialCandidates);
        };

        fetchData();
    }, [jobData, token]);

    const handleViewCandidate = (candidateId) => {
        navigate(`/candidatepersona/${candidateId}`, {
            state: { company_id, job_id }
        });
    };

    const addColumn = () => {
        setIsModalAddOpen(false);

        if (!newColumnName || isNaN(newColumnIndex) || newColumnIndex < 0 || newColumnIndex > columns.length || columns.includes(newColumnName)) {
            alert("Veuillez entrer un nom de colonne valide et un index correct.");
            return;
        }

        // Insérer la colonne à l'index spécifié
        const updatedColumns = [...columns];
        updatedColumns.splice(parseInt(newColumnIndex), 0, newColumnName);

        setNewColumnName('');
        setNewColumnIndex('');

        sendupdate({ ...candidates, [newColumnName]: [], }, updatedColumns);
    };

    const deleteColumn = (columnId) => {
        const updatedColumns = columns.filter((column) => column !== columnId);

        const updatedCandidates = { ...candidates };
        const removedCandidates = updatedCandidates[columnId] || [];
        delete updatedCandidates[columnId];

        if (updatedColumns.length > 0) {
            const firstColumn = updatedColumns[0];
            updatedCandidates[firstColumn] = [...(updatedCandidates[firstColumn] || []), ...removedCandidates];
            updatedCandidates[firstColumn].sort((a, b) => a.name.localeCompare(b.name));
        }

        sendupdate(updatedCandidates, updatedColumns);
    };

    const ItemType = 'candidate';

    const moveCandidate = (sourceColumn, destColumn, sourceIndex) => {
        if (sourceColumn === destColumn) {
            return;
        }

        const sourceColumnCopy = [...candidates[sourceColumn]];
        const destColumnCopy = [...candidates[destColumn]];

        const [candidate] = sourceColumnCopy.splice(sourceIndex, 1);
        destColumnCopy.push(candidate);

        sourceColumnCopy.sort((a, b) => a.name.localeCompare(b.name));
        destColumnCopy.sort((a, b) => a.name.localeCompare(b.name));

        sendupdate({ ...candidates, [sourceColumn]: sourceColumnCopy, [destColumn]: destColumnCopy }, columns);
    };

    const Column = ({ columnId, children }) => {
        const [, drop] = useDrop({
            accept: ItemType,
            drop: (item) => {
                moveCandidate(item.column, columnId, item.index);
            },
        });

        return (
            <div ref={drop} className={styles["column"]}>
                <h2>{columnId}</h2>
                <div className={styles["column-container"]}>{children}</div>
                {userType === 'admin' && (
                    <div style={{ height: '60px' }}>
                        <button onClick={() => deleteColumn(columnId)} className={styles["delete-column-button"]}>Supprimer</button>
                    </div>
                )}
            </div>
        );
    };

    const Candidate = ({ candidate, index, columnId }) => {
        const [, drag] = useDrag({
            type: ItemType,
            item: { id: candidate.id, column: columnId, index },
        });

        return (
            <div ref={drag} className={styles["candidate"]}>
                {candidate.name}
                <button onClick={() => handleViewCandidate(candidate.id)} className={styles["view-button"]}>
                    Voir
                </button>
            </div>
        );
    };

    const closeModal = () => {
        setIsModalAddOpen(false);
    };

    return (
        <div>
            <h1 className={styles["title"]}>Processus de candidature</h1>
            <p>
                Vous pouvez voir les candidats et les déplacer entre les colonnes. Les colonnes sont triées par ordre alphabétique.
            </p>
            {userType !== 'admin' && (
                <p>
                    Vous n'avez pas les droits pour ajouter ou supprimer des colonnes, demandez à un administrateur.
                </p>
            )}
            <br />
            <DndProvider backend={HTML5Backend}>
                <div className={styles["columns"]}>
                    {columns.map((column) => (
                        <Column key={column} columnId={column}>
                            {candidates[column]?.map((candidate, index) => (
                                <Candidate
                                    key={candidate.id}
                                    candidate={candidate}
                                    index={index}
                                    columnId={column}
                                />
                            ))}
                        </Column>
                    ))}
                </div>
            </DndProvider>
            {userType === 'admin' && (
                <div className={styles["footer"]}>
                    <button onClick={() => setIsModalAddOpen(true)} className={styles["add-column-button"]}>
                        Ajouter une colonne
                    </button>
                </div>
            )}
            <Modal
                isOpen={isModalAddOpen}
                onClose={closeModal}
                contentLabel="add-column"
                className={styles["modal"]}
                overlayClassName={styles["overlay"]}
            >
                <h2 className={styles["modal-title"]}>
                    Ajouter une colonne
                </h2>
                <div className={styles["input-group"]}>
                    <input
                        type="text"
                        placeholder="Nom de la colonne"
                        value={newColumnName}
                        onChange={(e) => setNewColumnName(e.target.value)}
                        className={styles["input"]}
                    />
                    <input
                        type="number"
                        placeholder="Index"
                        value={newColumnIndex}
                        onChange={(e) => setNewColumnIndex(e.target.value)}
                        className={styles["input"]}
                    />
                </div>
                <div className={styles["input-group"]}>
                    <button onClick={addColumn} className={styles["add-button"]}>
                        Ajouter une colonne
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ProcessCandidate;
