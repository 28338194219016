// src/utils/Auth.js
import Cookies from 'js-cookie';

// Set the session token in a cookie
export const setSessionToken = (token) => {
    Cookies.set('sessionToken', token, {
        expires: 1, // Expires in 1 day
        secure: true, // Use only over HTTPS
        sameSite: 'Strict', // Protect against CSRF attacks
        path: '/', // Available across the whole site
    });
};

// Get the session token from the cookie
export const getSessionToken = () => {
    return Cookies.get('sessionToken');
};

// Remove the session token from the cookie
export const removeSessionToken = () => {
    Cookies.remove('sessionToken', { path: '/' });
};

// Get the user's type (admin, company user, user)
export const getUserType = async () => {
    const token = getSessionToken();
    if (token) {
        try {
            const response = await fetch('https://api.wanteed-job.com/company/get-user-companies', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'session-key': token,
            },
        });

            if (!response.ok) {
            console.error('Failed to fetch user type:', response.statusText);
            return null;
        }

            const data = await response.json();

            if (Array.isArray(data)) {
                // Handle array response
                if (data.length === 0) {
                    return 'user';
    } else {
                    const adminFound = data.some(company => company.permission === 'ADMIN');
                    return adminFound ? 'admin' : 'company user';
    }
            } else if (typeof data === 'object' && data !== null) {
                // Handle single object response
                if (data.permission === 'ADMIN') {
                    return 'admin';
                } else {
                    return 'company user'; // Assuming a single object implies company user if not admin
                }

            } else {
                console.error('Unexpected data format:', data);
                return null;
            }
        } catch (error) {
            console.error('Error fetching user type:', error);
            return null;
        }
    } else {
        return null;
    }
};