import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/JobModal.module.css';

const JobModal = ({ selectedCompany, closeModal, fetchJobs, edit = false, data = {
    title: '',
    domain_id: '',
    job_type: '',
    salary: 0,
    city: '',
    country: '',
    description: '',
    redirection: '',
    active: true,
} }) => {
    const [formData, setFormData] = useState(data);
    const [categories, setCategories] = useState([]);
    const [domains, setDomains] = useState({});
    const token = getSessionToken();
    /* const [questions, setQuestions] = useState([]);

    const addQuestion = () => {
        setQuestions([
            ...questions,
            { id: questions.length + 1, text: "" } // Add a new empty question
        ]);
    };

    const removeQuestion = (id) => {
        setQuestions(questions.filter(question => question.id !== id));
    };

    const handleQuestionChange = (id, newText) => {
        setQuestions(questions.map(question =>
            question.id === id ? { ...question, text: newText } : question
        ));
    };
 */
    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await fetch('https://api.wanteed-job.com/job/get-domains', {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                setCategories(Object.keys(data));
                setDomains(data);
            }
            catch (error) {
                console.error('Error fetching domains:', error);
            }
        };

        fetchDomains();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // console.log(formData);
        // If "active" is undefined, set it to false
        if (formData.active === undefined) {
            setFormData((prevData) => ({
                ...prevData,
                active: false,
            }));
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        formData.city = formData.city.toLowerCase();
        formData.country = formData.country.toLowerCase();

        try {
            const response = await fetch(edit ? `https://api.wanteed-job.com/company/edit-job` : 'https://api.wanteed-job.com/company/add-job', {
                method: edit ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token,
                },
                body: JSON.stringify({
                    company_id: selectedCompany.company_id,
                    ...formData,
                }),
            });
            console.log(response);
            closeModal();
            fetchJobs(selectedCompany.company_id);
            //TODO replace redirectionUrl with job's redirection URL
        } catch (error) {
            console.error('Error adding job:', error);
        } finally {
            setIsLoading(false);
            console.log('Job upload successfully');
        }
    };


    return (
        <div style={{ width: '100%', height: '100%' }}>
            {isLoading ? (
                <div className={styles["loading-screen"]}>
                    <div className={styles["loading-bar"]}></div>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className={styles["modal-form"]}>
                    <div>
                        <label>Titre de l'offre</label>
                        <input type="text" name="title" value={formData.title} onChange={handleInputChange} required />
                    </div>
                    <div>
                        <label>Domaine</label>
                        <select name="domain_id" value={formData.domain_id} onChange={handleInputChange} required>
                            <option value="">Selectionner Domaine</option>
                            {categories.map((category) => (
                                <optgroup key={category} label={category}>
                                    {domains[category].map((domain) => (
                                        <option key={domain.id} value={domain.id}>
                                            {domain.profession}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label>Type de contrat</label>
                        <select name="job_type" value={formData.job_type} onChange={handleInputChange} required>
                            <option value="">Selectionner Type de contrat</option>
                            <option value="CDI">CDI</option>
                            <option value="CDD">CDD</option>
                            <option value="Stage">Stage</option>
                            <option value="Alternance">Alternance</option>
                            <option value="Freelance">Freelance</option>
                        </select>
                    </div>

                    <div>
                        <label>Salaire annuel</label>
                        <input type="text" name="salary" value={formData.salary} onChange={handleInputChange} required />
                    </div>

                    <div>
                        <label>Ville</label>
                        <input type="text" name="city" value={formData.city} onChange={handleInputChange} required />
                    </div>

                    <div>
                        <label>Pays</label>
                        <input type="text" name="country" value={formData.country} onChange={handleInputChange} required />
                    </div>

                    <div className={styles['full-width']}>
                        <label>Description</label>
                        <textarea name="description" value={formData.description} onChange={handleInputChange} required></textarea>
                    </div>

                    {/* <div className={styles['custom-test']}>
                        <h4>Questions personnalisées</h4>
                        <p>Ajoutez des questions personnalisées pour ce poste (optionnel)</p>
                        {questions.map((question, index) => (
                            <div key={question.id} className={styles['custom-test-question']}>
                                <input
                                    type="text"
                                    name={`question-${question.id}`}
                                    placeholder={"Comment décririez-vous votre expérience avec..."}
                                    value={question.text}
                                    onChange={(e) => handleQuestionChange(question.id, e.target.value)}
                                />
                                <button
                                    type="button"
                                    className={styles['button-question-remove']}
                                    onClick={() => removeQuestion(question.id)}
                                >
                                    -
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            className={styles['button-question-add']}
                            onClick={addQuestion}
                        // margin important to override default button margin
                        >
                            Ajouter une question
                        </button>
                    </div> */}

                    <div className={styles["large-width"]}>
                        <label>URL de redirection</label>
                        <input type="text" name="redirection" value={formData.redirection} onChange={handleInputChange} placeholder='https://www.example.com' />
                    </div>

                    <div className={styles["large-width"]}>
                        <label>Activer l'offre</label>
                        <input type="checkbox" name="active" checked={formData.active} onChange={handleInputChange} />
                    </div>
                    {edit && <p className={styles["large-width"]}>
                        Attention si vous modifiez la description de l'offre, elle sera entièrement recalculée et les compétences associées seront mises à jour.
                    </p>
                    }
                    <div className={styles["button-container"]}>
                        <button type="submit" className={styles['button-submit']}>Valider</button>
                        <button type="button" onClick={closeModal} className={styles['button-cancel']}>Annuler</button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default JobModal;
