// src/pages/HardSkillsPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExperiencesModal from '../components/ExperiencesModal';
import ExperienceList from '../components/ExperienceList';
import TestSwitcher from '../components/TestSwitcher';
import { getSessionToken, setSessionToken } from '../utils/Auth';
import styles from '../styles/HardSkillsPage.module.css';  // Utilisation d'un module CSS

const HardSkillsPage = () => {
    const [token, setToken] = useState(null);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [experiences, setExperiences] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    const openModal = () => setIsModalOpen(true);

    const closeModal = () => {
        setIsModalOpen(false);
        setRefreshTrigger(!refreshTrigger);
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Store the file in state
        setFileName(e.target.files[0].name); // Store the file name in state
    }

    useEffect(() => {
        const token = getSessionToken();
        if (!token) {
            fetchDummyRegister();
        } else {
            setToken(token);
        }
    }, []);

    const fetchDummyRegister = async () => {
        try {
            const response = await fetch('https://api.wanteed-job.com/credential/get-dummy-register', {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setToken(data);
            setSessionToken(data);
            localStorage.setItem('dummyRegister', 'true');
        } catch (error) {
            console.error('Failed to get session key. Please try again.');
        }
    };

    const analyzeResume = async (e) => {
        e.preventDefault();
        setToken(getSessionToken());
        console.log("getSessionToken", token);
        if (!token) {
            fetchDummyRegister();
        }
        setLoading(true);
        // Use POST /user/resume to analyze the resume
        e.preventDefault();
        const formData = new FormData();
        console.log('token', token);
        formData.append('file', file);

        try {
            const response = await fetch('https://api.wanteed-job.com/user/resume', {
                method: 'POST',
                headers: {
                    'session-key': token
                },
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                console.log('Resume analysis:', data);
                setExperiences(data);
            } else {
                console.error('Failed to analyze resume');
            }
        }
        catch (error) {
            console.error('Error analyzing resume:', error);
        }
        setLoading(false);
        openModal();
    };

    return (
        <div className={styles.hardSkillsPage}>
            <TestSwitcher activeTest="hardskills" />
            {isModalOpen && <ExperiencesModal experiences={experiences.experience} onClose={closeModal} />}
            <div className={styles.flexbox}>
                <div className={styles.containerExperience}>
                    <div className={styles.section}>
                        <h2>Vos expériences</h2>
                        <ExperienceList refreshTrigger={refreshTrigger} />
                    </div>
                </div>
                <div className={styles.containerCV}>
                    <div className={styles.section}>
                        {loading ? (
                            <div className={styles["loading-screen"]}>
                                <div className={styles["loading-bar"]}></div>
                            </div>
                        ) : null}
                        <h2>Importez votre CV</h2>
                        {!loading && <p>Importez votre CV pour extraire vos expériences automatiquement</p>}
                        {loading && <p>Analyse de votre CV en cours...<br />Ne pas recharger la page</p>}
                        <form onSubmit={analyzeResume}>
                            <label className={styles.fileInputContainer}>
                                <input type="file" onChange={handleFileChange} />
                                <div className={styles.customFileInput}>
                                    <span>{fileName || 'Importez votre CV'}</span>
                                </div>
                            </label>
                            <button>Importer et analyser</button>
                        </form>
                    </div>
                    {localStorage.getItem('dummyRegister') === 'true' ? (
                        <div className={styles.section}>
                            <button onClick={() => navigate('/register')} className={styles.registerButton}>Finaliser mon inscription</button>
                        </div>
                    ) : (
                        <div className={styles.section}>
                            <button onClick={() => navigate('/jobs')} className={styles.registerButton}>Voir les offres</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HardSkillsPage;
